import React from 'react'

import NoLayout from '../components/layout/nolayout'

import ContactForm from '../components/contact/contactForm'

import '../components/contact/contact.css'

const ContactPage = () => (
  <NoLayout>
    <div className="contact-form-outer-container">
      <ContactForm />
    </div>
  </NoLayout>
)

export default ContactPage
